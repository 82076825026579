import logo from '../assets/logo.svg';
import '../styles/Home.css';

function Home() {
  return (
    <div className="Home">
      <header className="Home-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Web version coming soon! Check us out on the App Store.</p>
      </header>
    </div>
  );
}

export default Home;
