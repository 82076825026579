import '../styles/Support.css';
import MailToButton from '../components/MailToButton';

function Contact() {
    return (
        <div className="Contact">
            <header className="Support-header">
                <p>Please send us an email:</p>
                <MailToButton label="contact@rallytraders.com" mailto="mailto:support@rallytraders.com" />
            </header>
        </div>
    );
}

export default Contact;