import React, { useEffect } from 'react';

function AppleSignIn() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.async = true;
    script.onload = () => {
      if (window.AppleID) {
        window.AppleID.auth.init({
          clientId: 'com.rallytraders.RallyWeb', // Replace with your own client ID
          scope: 'name email',
          redirectURI: 'https://www.rallytraders.com/account', // Replace with your redirect URI
          usePopup: true, // Set to true if you prefer the popup experience
        });
      }
    };

    document.addEventListener('AppleIDSignInOnSuccess', (event) => {
      console.log("success")
      console.log(event)
      console.log(event["detail"]["authorization"])

      const authorization = event["detail"]["authorization"];

      // const data = {
      //   identityToken: authorization.id_token,  // The ID token received from Apple
      //   code: authorization.code,  // The authorization code
      //   user: event["detail"]["user"],  // User information (if provided by Apple)
      // };

      const data = {
        identityToken: authorization.id_token  // The ID token received from Apple
      };

      console.log(JSON.stringify(data))

      fetch('https://api.rallytraders.com/v1/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log('Successfully posted authorization:', data);
          sessionStorage.setItem("authToken", data["accessToken"])
          sessionStorage.setItem("userID", data["userID"])
          window.dispatchEvent(new Event("storageChange"));

          const authToken = sessionStorage.getItem("authToken")
          console.log("authToken: ", authToken)
        })
        .catch(error => {
          console.error('Error posting authorization:', error);
        });
    });

    document.addEventListener('AppleIDSignInOnFailure', (event) => {
      console.log("failure")
      console.log(event);
    });

    document.body.appendChild(script);
  }, []);

  return (
    <div
      id="appleid-signin"
      data-color="white"
      style={{ width: '200px', height: '40px' }}
    />
  );
};

export default AppleSignIn;
