import React, { useState } from 'react';
import '../styles/Navbar.css';
import logo from '../assets/small-logo.svg';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="nav-logo">
        <a href="/">
          <img src={logo} alt="Logo" />
        </a>
      </div>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        {/* <li><a href="/about">About</a></li> */}
        <li><a href="/account">Account</a></li>
        <li><a href="/support">Support</a></li>
        <li><a href="/contact">Contact</a></li>
      </ul>
      <div className="nav-hamburger" onClick={handleToggle}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </nav>
  );
}

export default Navbar;
