import '../styles/NotFound.css';

function NotFound() {
  return (
    <div className="NotFound">
      <header className="NotFound-header">
        <p>Are you lost?</p>
      </header>
    </div>
  );
}

export default NotFound;