import AppleSignIn from '../components/AppleSignIn';
import '../styles/Support.css';
import React, { useEffect, useState } from 'react';

function Account() {
    const [userId, setUserId] = useState(sessionStorage.getItem("userID") || null);

    useEffect(() => {
        // Function to handle the storage event
        const handleStorageChange = () => {
            const storedUserId = sessionStorage.getItem("userID");
            const authToken = sessionStorage.getItem("authToken");
            setUserId(storedUserId);


            fetch('https://api.rallytraders.com/v1/user/account', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Successfully got account:', data);
                })
                .catch(error => {
                    console.error('Error posting authorization:', error);
                });
        };

        // Add event listener for storage changes
        window.addEventListener("storageChange", handleStorageChange);
        handleStorageChange()

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("storageChange", handleStorageChange);
        };
    }, []);
    return (
        <div className="Account">
            <header className="Support-header">
                {userId ? (
                    <p>Welcome, User ID: {userId}</p>
                ) : (
                    <AppleSignIn />
                )}
            </header>
        </div>
    );
}

export default Account;
